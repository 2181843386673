import React, { FC, ReactNode } from 'react';
import { CommunityInfoType } from '@/features/Challenges/types';
import AuthNavBar from './AuthNavbar';
import { useAuthContext } from '@/contexts/AuthContext';
import useUserCommunitiesData from '@/hooks/useUserCommunitiesData';
import classNames from 'classnames';

type AuthNavbarPageLayoutProps = {
  communityInfo: CommunityInfoType;
  children: ReactNode | ((userCommunitiesData: unknown) => ReactNode);
  isCommunityPageView?: boolean;
};

const AuthNavbarPageLayout: FC<AuthNavbarPageLayoutProps> = ({
  communityInfo,
  children,
  isCommunityPageView = false
}) => {
  const { isLoggedIn } = useAuthContext();

  const userCommunitiesData = useUserCommunitiesData({ communityInfo });

  const {
    userCommunities,
    isPendingApproval,
    isCommunityAdmin,
    isCommunityMember,
    openSignUpModal
  } = userCommunitiesData;

  return (
    <>
      {isLoggedIn && (
        <div
          className={classNames(
            'top-0 z-over-intercom flex h-64 w-full items-center px-12 sticky',
            { 'bg-white-default': !isCommunityPageView }
          )}>
          <div className="flex-grow">
            <AuthNavBar
              communityInfo={communityInfo}
              userCommunities={userCommunities}
              isCommunityAdmin={isCommunityAdmin}
              isCommunityMember={isCommunityMember}
              isPendingApproval={isPendingApproval}
              openSignUpModal={openSignUpModal}
            />
          </div>
        </div>
      )}
      {typeof children === 'function'
        ? children(userCommunitiesData)
        : children}
    </>
  );
};

export default AuthNavbarPageLayout;
